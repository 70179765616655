import React from "react";
import { userHasAccess } from "../libs/helper";
import moment from "moment-timezone";
let posts = {
  name: "Posts",
  slug: "posts",
  singularName: "Post",
  crud: true,
  endpoint: "/crud/timeline_posts",
  updateLabel: "name",
  filters: [
    
  ],
  meta: {
    delete_flag: true,
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Post"
  },
  access: ["superadmin"],
  delete: true,
  add: true,
  dataPostFilters: true,
  dataAuthors: true,
  dataAuthorsEndPoint: '/v1/authors',
  dataCategories: true,
  dataCategoriesEndPoint: '/v1/category',
  update: true,
  edit: true,
  actions: [
     {
        name: "comments",
        icon: "i-Download",
        label: "Comments",
        uri:(rowData)=>{
       
          return '/dashboard/comments';
        },
        onPush:(rowData)=>{
        
          const {id} = rowData;
          return {data:{id}};
        },
        redirect:true,
    }
  ],
  beforeUpdate: (values) => {
    return {
      content: values.content,
      post_status: values.post_status,
      symbol: values.symbol,
      title: values.title,
      is_pinned: values.is_pinned,
    };
  },
  fields: [
    {
      label: "Date",
      type: "text",
      name: "created_at",
      readonly: true,
      notAvailWhileAdd: true,
      // input: false,
      resource_label:(row)=>{
        if(!row) return "";
        return moment(row.created_at).tz('Asia/Jakarta').format("YYYY/MM/DD HH:mm") + ' WIB';
      },
    },
    {
      label: "Title",
      name: "title",
      type: "text",
    },
    {
      label: "Username",
      name: "accounts_username",
      type: "text",
      defaultValue: (row) => {
        return row.accounts_username;
      },
    },
    {
      label: "Content",
      name: "content",
      type: "textarea",
      visible:false,
    },
    {
      label: "Type/Symbol",
      name: "symbol",
      type: "text",
    },
    {
      label: "Pinned",
      name: "is_pinned",
      type: "lookup",
      values: [0,1],
      resource_label: (row)=>{
        if(!row) return "";
        if(row.is_pinned === 0) return "No";
        if(row.is_pinned === 1) return "Yes";
      },
      defaultValue: (row) => {
        if(!row) return "";
        return row.is_pinned;
      }
    },
    {
      label: "Status",
      name: "post_status",
      type: "lookup",
      values: [
        {value:-2, text: 'Reject'},
        {value:-1, text: 'Delete'},
        {value:0, text: 'Draft'},
        {value:1, text: 'Publish'},
        {value:2, text: 'Pending'}
      ],
      resource_label: (row)=>{
        if(!row) return "";
        if(row.post_status === -2) return "Rejected";
        if(row.post_status === -1) return "Deleted";
        if(row.post_status === 0) return "Draft";
        if(row.post_status === 1) return "Published";
        if(row.post_status === 2) return "Pending";
      },
      defaultValue: (row) => {
        if(!row) return "";
        else return row.post_status;
      }
    },
  ],
};
let videos = {
  name: "Videos",
  slug: "videos",
  singularName: "Video",
  crud: true,
  endpoint: "/crud/videos",
  updateLabel: "name",
  filters: [
    
  ],
  meta: {
    delete_flag: true,
  },
  disableMenu: ["referral", "history"],
  menuLabel: {
    profile: "Video"
  },
  access: ["superadmin"],
  delete: true,
  add: true,
  update: true,
  edit: true,
  excludeAccountId: true,
  excludeMentorId: true,
  actions: [
     
  ],
  fields: [
   {
      label: "Date",
      resource_label:(row)=>{
        return moment(row.created_at).tz('Asia/Jakarta').format("DD-MM-YYYY HH:mm") + ' WIB';
      },
      input:false
    },
    {
      label: "Title",
      name: "title",
      type: "text",
    },
    {
      label: "Video URL",
      name: "video_url",
      type: "text",
    },
    {
      label: "HLS URL",
      name: "hls_url",
      type: "text",
    },
    {
      label: "Thumbnail URL",
      name: "thumbnail_url",
      type: "text",
    },
    {
      label: "Excerpt",
      name: "excerpt",
      type: "text",
      visible:false,
    },
    {
      label: "Status",
      name: "is_published",
      type: "lookup",
      values: [
        {value: 0, text: 'Draft'},
        {value: 1, text: 'Publish'},
      ],
      resource_label: (row)=>{
        if(!row) return "";
        if(row.is_published === 0) return "Draft";
        if(row.is_published === 1) return "Published";
      },
      defaultValue: (row) => {
        if(!row) return 0;
        return row.is_published;
      }
    },
    {
      label: 'Category',
      name: 'category',
      type: 'lookup',
      values: [
        {
          text: 'Mentor Basic',
          value: 'Mentor Basic',
        },
        {
          text: 'Mentor Bedah',
          value: 'Mentor Bedah',
        },
        {
          text: 'Mentor Beri Solusi',
          value: 'Mentor Beri Solusi',
        },
        {
          text: 'Market Update',
          value: 'Market Update',
        },
        {
          text: 'Temu Emiten',
          value: 'Temu Emiten',
        },
      ],
      resource_label: (row)=>{
        if(!row) return "";
        return row.category
      },
      defaultValue: (row) => {
        if(!row) return "Mentor Basic";
        return row.category;
      }
    }
  ],
};

let comments = {
  name: "Comments",
  slug: "comments",
  visible:false,
  singularName: "Comment",
  crud: true,
  endpoint: "/crud/post_comments",
  updateLabel: "name",
  backTo:'dashboard/posts',
  parentFilters: [
    {
      field: "post_id",
      value: (data) => {
        if(!data) return 0;
        return data.id;
      },
    },
  ], //default query filter
  meta: {
    delete_flag: true,
  },
  access: ["superadmin"],
  delete: true,
  add: false,
  update: true,
  edit: true,
  actions: [
    
  ],
  fields: [
   {
      label: "Date",
      type: "text",
      input: false,
      resource_label:(row)=>{
        return moment(row.created_at).tz('Asia/Jakarta').format("DD-MM-YYYY HH:mm") + ' WIB';
      }
    },
    {
      label: "From",
      name: "from",
      type: "text",
      resource_label:(row)=>{
        if(!row.profiles) return "N/A";
        return row.profiles.nama;
      },
      input: false,
    },
   
    {
      label: "Text",
      name: "txt",
      type: "text",
      visible:true,
    },
    {
      label: "Reply Of",
      name: "reply",
      type: "auto",
      visible:true,
      resource_label:(row)=>{
        try{
          if(!row.reply_id === 0) return "-"
          if(row.reply_data){
            let s = JSON.parse(row.reply_data);
            return s.txt;
          }
         return "-"
        }catch(err){
          return "-"
        }
        
      }
    },
  ],
};

let transactions = {
  name: "Transactions",
  slug: "transactions",
  singularName: "Transaction",
  crud: true,
  endpoint: "/crud/transactions",
  updateLabel: "name",
  filters: [
    
  ],
  meta: {
    delete_flag: true,
  },
  access: ["superadmin"],
  delete: false,
  add: false,
  update: false,
  edit: false,
  actions: [],
  fields: [
   {
      label: "Date",
      type: "text",
      input: false,
      resource_label:(row)=>{
        return moment(row.created_at).tz('Asia/Jakarta').format("DD-MM-YYYY HH:mm") + ' WIB';
      }
    },
    {
      label: "Order No.",
      name: "order_no",
      type: "text",
    },
    
     {
      label: "Status",
      name: "payment_status",
      type: "lookup",
      values: [0,1,2],
      resource_label: (row)=>{
        if(!row) return "";
        if(row.post_status === 0) return "Pending";
        if(row.post_status === 1) return "Paid";
      }
    },
  ],
};

export default [
  posts,
  transactions,
  comments,
  videos
];
